import React, { useEffect } from 'react';

import { ZoomMtg } from "@zoomus/websdk";
// import './Zoom.css';

var CryptoJS = require("crypto-js");

function Zoom(props) {

    const zoomMeeting = document.getElementById("zmmtg-root");

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let urlParams = params.get('query');

    let meetingData = {
        apiKey: '',
        apiSecret: '',
        meetingNumber: '',
        userName: '',
        userEmail: ''
    };

    if (urlParams && urlParams !== '') {
        String.prototype.replaceAll = function (search, replacement) {
            var target = this;
            return target.split(search).join(replacement);
        };

        urlParams = urlParams.replaceAll(' ', '+');

        var decUrlParams = CryptoJS.AES.decrypt(urlParams, 'Ebiz@2020');

        meetingData = JSON.parse(decUrlParams.toString(CryptoJS.enc.Utf8));
    }

    var leaveUrl = 'https://app.hirescholars.com/apps/events';

    const meetConfig = {
        apiKey: meetingData.apiKey,
        apiSecret: meetingData.apiSecret,
        meetingNumber: meetingData.meetingNumber,
        userName: meetingData.userName,
        passWord: '',
        leaveUrl: leaveUrl,
        role: 0
    };

    useEffect(() => {
        ZoomMtg.setZoomJSLib("https://source.zoom.us/1.8.3/lib", "/av");
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();

        zoomMeeting.style.display = 'block';

        // generateSignature should only be used in development
        ZoomMtg.generateSignature({
            meetingNumber: meetConfig.meetingNumber,
            apiKey: meetConfig.apiKey,
            apiSecret: meetConfig.apiSecret,
            role: meetConfig.role,
            success(res) {
                console.log('signature', res.result);
                ZoomMtg.init({
                    leaveUrl: leaveUrl,
                    success() {
                        ZoomMtg.join(
                            {
                                meetingNumber: meetConfig.meetingNumber,
                                userName: meetingData.userName,
                                signature: res.result,
                                apiKey: meetConfig.apiKey,
                                userEmail: meetingData.userEmail,
                                passWord: meetConfig.passWord,
                                success() {
                                    console.log('join meeting success');
                                },
                                error(res) {
                                    console.log(res);
                                }
                            }
                        );
                    },
                    error(res) {
                        console.log(res);
                    }
                });
            }
        });
    }, [leaveUrl, meetingData, meetConfig]);

    return (
        <div></div>
    );
}

export default Zoom;